import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Error404 } from '../../components/empty-state/Error404';
export const pageQuery = graphql`
  {
    allPasteComponent {
      nodes {
        name
        status
      }
    }
    allPasteLayout {
      nodes {
        name
        status
      }
    }
    allPastePrimitive {
      nodes {
        name
        status
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Error404 pathname={props.location.pathname} componentList={props.data.allPasteComponent.nodes} layoutList={props.data.allPasteLayout.nodes} primitiveList={props.data.allPastePrimitive.nodes} mdxType="Error404" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      